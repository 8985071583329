.dices-widget {
    position: fixed;
    cursor: pointer;
    bottom: 20px;
    right: 20px;

    .ui {
        width: 50px !important;

        svg {
            border: 2px solid rgba(0, 0, 0, .5);
        }
    }
}

.dices-widget:hover .image {
    background-color: lightgray;
}

.dices-widget-menu {
    position: absolute;
    margin: 0px !important;
}