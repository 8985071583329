.context-menu {
    position: absolute;
    z-index: 1000;
    /* background-color: white;
    cursor: pointer;
    -webkit-box-shadow: 2px 2px 6px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 2px 2px 6px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 2px 2px 6px 0px rgba(34, 60, 80, 0.2); */

    .item {
        padding: 6px !important;
    }

    .item:hover {
        background-color: #eee !important;
    }
}