.map-container {
    margin-top: 10px;
    border: 2px solid black;
    background-color: lightgrey;
    overflow: hidden;
    position: relative;

    img {
        position: absolute;
        z-index: 10;
    }

    i.map-point.icon {
        background-color: white !important;
        border: .2em solid black;
        width: 2.3em !important;
        height: 2.3em !important;
        font-size: x-large;
        position: absolute;
        z-index: 100;
        cursor: pointer;
    }

    .map-moving-point {
        cursor: move !important;
    }
}