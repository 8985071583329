.ui.modifier.button {
    width: 30px !important;
    padding-left: 0;
    padding-right: 0;
}

.ui.bonus.button {
    background-color: transparent;
}

.ui.bonus.button:hover {
    background-color: rgba(0, 0, 0, .1);
}

.ui.input.skill {
    width: 50px !important;
}

.card-header-panel {
    width: 100%;
    height: 56px;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
}

.card-header-panel-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 56px;
    width: 100%;
}

.card-header-panel-title > .input {
    width: 100%;
}

.card-header-panel-actions {
    width: 100%;
    min-height: 40px;
    margin: auto;
    display: flex;
    justify-content: right;
}

.remove-button {
    cursor: pointer;
    margin-left: 0.5em !important;
}

.person-first-row {
    width: 100%;

    .person-icon {
        display: inline-block;
        width: 140px;
    }

    .person-other-info {
        display: inline-block;
        width: 100%;
        margin-left: -140px;
        padding-left: 140px;
        vertical-align: top;
    }
}

.title-input {
    margin-left: 10px;
    margin-right: 10px;
}

.adventure-header {
    position: relative;
    width: 100%;

    .header-main {
        position: relative;
        width: 100%;
        padding-right: 304px;

        .header-back {
            position: absolute;
            left: 14px;
            width: 40px;

            a {
                width: 100%;
            }
        }

        .header-title {
            width: 100%;
            padding-left: 60px;

            .input {
                width: 100%;
            }
        }
    }

    .header-links {
        position: absolute;
        right: 12px;
        width: 274px;

        .button {
            margin-left: 6px;
        }

        .header-up {
            width: 172px;
            padding-left: 3.2em !important;

            .up-label {
                width: 100px;
                display: inline-block;
                text-overflow: ellipsis;
                overflow: hidden; 
                white-space: nowrap;
            }
    }
    }

    div {
        height: 36px;
    }
}

.adventure-person-header {
    .header-main {
        padding-right: 160px;
    }
    .header-links {
        width: 138px;
    }
}

.adventure-chapter-header {
    .header-main {
        padding-right: 160px;
    }
    .header-links {
        width: 138px;
    }
}

.adventure-place-header {
    .header-main {
        padding-right: 214px;
    }
    .header-links {
        width: 182px;
    }
}

.adventure-subpage-header {
    margin-top: 10px !important;
    margin-bottom: -10px !important;
    font-weight: 400 !important;

    b {
        font-weight: 700 !important;
        margin-left: 10px;
    }

    .icon {
        margin-top: -4px !important;
        margin-right: 0px !important;
    }
}

.adventure-subpage-header:not(:has(.icon)) {
    cursor: pointer;
}

div.inline-title {
    display: inline-block;
    margin-left: 0px !important;
    margin-right: 10px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    line-height: 32px !important;
    vertical-align: bottom !important;
}

.list .item {
    .icon {
        display: inline !important;
    }
    
    .content {
        display: inline !important;
    }
}

.description span {
    margin-right: 4px;
}

.description span:not(:first-child) {
    margin-left: 12px;
}

.icon-dropdown {
    min-width: 0 !important;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #aaa;
    outline: none;
    transition: border .24s ease-in-out;
    cursor: pointer;
    height: 260px;

    .dropzone-preview {
        height: 160px;
        border-width: 1px;
        border-color: #aaa;
        border-style: dashed;
        margin-bottom: 6px;

        img {
            width: 100%;
            height: 100%; 
            object-fit: contain;
        }
    }

    .dropzone-preview.no-selection {
        width: 160px;
    }

    .dropzone-file {
        color: #666;
    }
}

.dropzone:focus, .dropzone:hover {
    border-color: #999;
}

.dropzone.disabled {
    opacity: 0.6;
}

.ui.form .wide.field .selection.dropdown {
    width: 100% !important;
}
  
.mdxeditor {
    border: 2px solid #eee;
    border-radius: var(--radius-medium);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;

    .mdxeditor-root-contenteditable > div {
        min-height: 10em;
    }
}

.required-label {
    white-space: nowrap;

    span {
        color: red;
    }
}
